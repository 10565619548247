import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable()
export class AddAuthHeaderInterceptor implements HttpInterceptor {
	constructor(private _cookie: CookieService, private _router: Router) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		try {
			let { headers } = request;
			const token = this._cookie.get(environment.tokenKey);
			if (!token) {
				throw new Error("Token not found");
			}
			headers = headers.set("Authorization", `Bearer ${token}`)
			return next.handle(request.clone({ headers }));
		} catch (error) {
			console.error("Failed to add appropriate headers.", error);
			this._router.navigate(["/error/unauthorized"]);
			return;
		}
	}
}
