import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import API_DETAILS from "../config/api-endpoints";
import { Content } from "../config/data-objects";
import DOCUMENT_TYPE from "../config/document-type";
import { AnalyzeLanguage, ApiResponse, FetchSopResponse } from "../config/reponse";
import { reportMapping as oldReportMapping } from "src/app/config/mappings";
import {
	AdditionalDetailsRequest,
	AnalyzeQuestionRequest,
	AnalyzeRequest,
	AutosaveRequest,
	BasicReportRequest,
	FeedbackRequest,
	StatusUpdateRequest,
} from "../config/requests";
import {
	BasicReportApiResponse,
	LanguageAnalysisApiData,
	RuntimeReport,
	SOPResponse,
	SOPResponseData,
	SuggestionMappingApiResponse,
} from "../types";
import { BASIC_REPORT_SECTIONS } from "../enums";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	api = environment.apiLink;
	constructor(public _http: HttpClient, private _snackbar: MatSnackBar) {}

	analyzeContent = (request: AnalyzeRequest): Promise<ApiResponse<Content>> => {
		return this._http.post(`${this.api}${API_DETAILS.ANALYZE_CONTENT}`, request).toPromise() as Promise<ApiResponse<Content>>;
	};

	analyzeLanguage = (request: AnalyzeRequest): Promise<ApiResponse<LanguageAnalysisApiData>> => {
		return this._http.post<ApiResponse<LanguageAnalysisApiData>>(`${this.api}${API_DETAILS.ANALYZE_LANGUAGE}`, request).toPromise();
	};

	autosave = (request: AutosaveRequest): Promise<ApiResponse<unknown>> => {
		return this._http.post(`${this.api}${API_DETAILS.AUTOSAVE}`, request).toPromise() as Promise<ApiResponse<unknown>>;
	};

	timepass = (url: string, req: object) => {
		return this._http.get(url, req);
	};

	createSOPID = (token: object): Observable<ApiResponse<unknown>> => {
		return this._http.post<ApiResponse<unknown>>(`${this.api}${API_DETAILS.CREATE_SOP}`, token);
	};

	login = (credentials: object) => {
		return this._http
			.post(`${this.api}${API_DETAILS.LOGIN}`, credentials)
			.pipe(
				catchError((err) => {
					return throwError(err);
				}),
			)
			.toPromise();
	};

	fetchSOP = (id: string, documentType: DOCUMENT_TYPE): Promise<SOPResponse> => {
		let params = new HttpParams();
		params = params.set("id", id);
		params = params.set("document_type", documentType);
		return this._http
			.get<SOPResponse>(`${this.api}${API_DETAILS.FETCH_SOP}?`, {
				// headers: header,
				params: params,
			})
			.toPromise();
	};

	updateSOPStatus = (request: StatusUpdateRequest): Promise<ApiResponse<unknown>> => {
		// HTTP Post request that will update the status of the SOP to IN_REVIEW
		return this._http.post(`${this.api}${API_DETAILS.UPDATE_SOP_STATUS}`, request).toPromise() as Promise<ApiResponse<unknown>>;
	};

	sendFeedback = (request: FeedbackRequest): Promise<ApiResponse<unknown>> => {
		return this._http.post(`${this.api}${API_DETAILS.FEEDBACK}`, request).toPromise() as Promise<ApiResponse<unknown>>;
	};

	/**
	 * Require inorder to send the details of question entered by the user to the middleware.
	 * @param question_text: string
	 * @param word_limit: number
	 * @param id: string
	 * @param token: string
	 */

	sendQuestion = (request: AnalyzeQuestionRequest): Promise<ApiResponse<unknown>> => {
		return this._http.post(`${this.api}${API_DETAILS.ANALYZE_QUESTION}`, request).toPromise() as Promise<ApiResponse<unknown>>;
	};

	getBasicReport(request: BasicReportRequest): Promise<RuntimeReport> {
		return forkJoin({
			report: this._http.post<BasicReportApiResponse>(`${this.api}${API_DETAILS.REPORT}`, request),
			reportSuggestionsMapping: this._http.get<SuggestionMappingApiResponse>(`${this.api}${API_DETAILS.SUGGESTION_MAPPING}`),
		})
			.pipe(
				map(this._processReport),
				catchError((err) => {
					this._snackbar.open("Seems there was some error in fetching your report.", "Dismiss", {
						duration: 2500,
					});
					return throwError(err);
				}),
			)
			.toPromise();
	}

	submitAdditionalDetails(request: AdditionalDetailsRequest): Promise<ApiResponse<unknown>> {
		return this._http.post(`${this.api}${API_DETAILS.ADDITIONAL_DETAILS}`, request).toPromise() as Promise<ApiResponse<unknown>>;
	}
	getSuggestionMessages(): Promise<SuggestionMappingApiResponse> {
		return this._http
			.get<SuggestionMappingApiResponse>(`${this.api}${API_DETAILS.SUGGESTION_MAPPING}`)
			.pipe(
				tap((apiSuggestions: SuggestionMappingApiResponse) => {}),
			)
			.toPromise();
	}

	private _processReport = ({
		report,
		reportSuggestionsMapping,
	}: {
		report: BasicReportApiResponse;
		reportSuggestionsMapping: SuggestionMappingApiResponse;
	}): RuntimeReport => {
		const { version, data: reportData } = report;
		const flattenedSuggestions = {
			...reportSuggestionsMapping.paired_suggestions,
			...reportSuggestionsMapping.primary_suggestions,
		};

		// if the version of the report is v1, change reportMapping completely to use the one saved in the front-end

		// metric = ["caution", "feedback", "good_points", "must_haves"]
		// keys are Q1,Q2,S1,S2,etc.
		// Create Empty object which will act as a temporary object that will store the report details
		let tempObj: RuntimeReport = {};
		// Run a loop on the reports object, based on the number of keys it has
		Object.keys(reportData).forEach((metric: BASIC_REPORT_SECTIONS) => {
			// Create a temporary empty array in which a mapped question will be pushed based on the metric & the key it is supposed to be mapped to
			let tempArray = [];

			// Run a loop on the number of keys that are present for each metric
			reportData[metric].forEach((key: string, index: number) => {
				// if a metric has no keys i.e. the metric is not applicable for the SOP, we push null
				if (!reportData[metric].length) {
					tempArray.push(null);
				}

				// if version is v2
				if (version == "v2") {
					switch (metric) {
						case "caution": {
							tempArray.push(key);
							break;
						}
						default: {
							const [x] = Object.keys(key);
							const [y] = Object.values(key);

							tempArray.push(flattenedSuggestions[x][y]);
							break;
						}
					}
				}
				// if version is v1
				else {
					const { paired_questions, primary_questions } = oldReportMapping;
					// if the metric is feedback based on peers, which has different mapping pairs, push from paired_questions
					// else push from primary_questions
					if (metric === "feedback") {
						tempArray.push(paired_questions[key]);
					} else if (metric === "caution") {
						tempArray.push(key);
					} else {
						tempArray.push(primary_questions[key]);
					}
				}
			});

			// In the empty object initialize a property i.e. metric with the value as the temporary array
			tempObj[metric] = tempArray;
		});

		return tempObj;
	};
}
