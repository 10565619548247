import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// NgxCookieConsent
import { NgcCookieConsentModule } from "ngx-cookieconsent";
// device detector
import { NavbarComponent } from "src/app/components/navbar/navbar.component";
import { cookieConfig } from "src/app/config/cookie-consent";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./guards/auth.guard";
import { RequestInterceptor } from "./interceptors/interceptors";
import { CookieService } from "ngx-cookie-service";
import { AddAuthHeaderInterceptor } from "./interceptors/add-headers.interceptors";
import { QuillModule } from "ngx-quill";
import "src/app/quill-modules/grammar-module.quill";
import { FooterComponent } from "./components/footer/footer.component";
import { UnsupportedMessageComponent } from "./components/unsupported-message/unsupported-message.component";
@NgModule({
	declarations: [AppComponent, NavbarComponent, FooterComponent, UnsupportedMessageComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatSnackBarModule,
		MatButtonToggleModule,
		QuillModule.forRoot({
			theme: "bubble",
			modules: {
				toolbar: false,
				clipboard: {
					matchVisual: false,
				},
			},
		}),

		NgcCookieConsentModule.forRoot(cookieConfig),
	],
	providers: [
		AuthGuard,
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddAuthHeaderInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
