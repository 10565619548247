import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { LoginModule } from "./pages/login/login.module";
import { ToolModule } from "./pages/tool/tool.module";

const routes: Routes = [
	{
		path: "",
		redirectTo: "tool",
		pathMatch: "full",
	},
	// { path: "login", loadChildren: (): Promise<LoginModule> => import("./pages/login/login.module").then((m) => m.LoginModule) },
	{
		path: "tool",
		loadChildren: (): Promise<ToolModule> => import("./pages/tool/tool.module").then((m) => m.ToolModule),
		canActivate: [AuthGuard],
	},
	{ path: "error", loadChildren: () => import("./pages/error/error.module").then((m) => m.ErrorModule) },
	{ path: "**", redirectTo: "error/page-not-found" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
