import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(private _router: Router, private _cookie: CookieService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			let token = this._cookie.get(environment.tokenKey);
			if (!token) {
				throw new Error("no token found");
			}
			return true;
		} catch (error) {
			console.error("An error has occured: ", error);
			this._router.navigate(["/error/unauthorized"]);
			return false;
		}
	}
}
