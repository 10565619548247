export const environment = {
	production: true,
	apiLink: "https://sop-middleware-api-prod.ischoolconnect.com",
	cookieDomain: "ischoolconnect.com",
	tokenKey: "SOP_ACCESS_TOKEN",
	cdnBaseUrl: "https://cdn.ischoolconnect.com",
	studentLogrocketId: "comozl/student-dashboard-prod",
	advisorLogrocketId: "comozl/advisor-dashboard-prod",
	advisorBackendUrl: "https://prod-advisor-api.ischoolconnect.com"
};
