import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	constructor(private _snackbar: MatSnackBar) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			tap(
				(event: HttpEvent<unknown>) => {},
				(err: unknown) => {
					if (err instanceof HttpErrorResponse) {
						console.error("Error is ", err);
						let url = err.url;
						let errorMessage: string = "";
						if (url.indexOf("/create_document") !== 0) {
							errorMessage = "Our servers are currently under maintenance. We apologize for the inconvinience caused";
						}
						this._snackbar.open(errorMessage, "", {
							duration: 2000,
						});
					}
				},
			),
		);
	}
}
