import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import LogRocket from "logrocket";
import { CookieService } from "ngx-cookie-service";
import { DeviceDetectorService } from "ngx-device-detector";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MetaService } from "./services/meta/meta.service";

declare const gtag;

@Component({
	selector: "sop-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	token: string;
	email: string;
	isIschoolBrandingAllowed: boolean;
	constructor(
		private _router: Router,
		public deviceDetector: DeviceDetectorService,
		private _cookie: CookieService,
		private _meta: MetaService,
	) {
		const logrocket =
			this._cookie.get("logrocket_identifier") === "advisor" ? environment.advisorLogrocketId : environment.studentLogrocketId;

		LogRocket.init(logrocket, { rootHostname: "ischoolconnect.com" });

		this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
			gtag("config", "UA-59661130-4", {
				page_path: e.urlAfterRedirects,
			});
		});
	}

	async ngOnInit(): Promise<void> {
		const response = await this._meta.fetchAndSetTheme();
		this.isIschoolBrandingAllowed = response.account_settings.is_isc_branding_allowed;
	}
}
