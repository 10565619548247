enum API_DETAILS {
	ANALYZE_CONTENT = "/analyze_content",
	ANALYZE_LANGUAGE = "/analyze_language",
	AUTOSAVE = "/autosave",
	LOGIN = "/login",
	UPLOAD = "/upload",
	CREATE_SOP = "/create_document",
	FETCH_SOP = "/document",
	UPDATE_SOP_STATUS = "/status_update",
	FEEDBACK = "/feedback",
	REPORT = "/document_text_report",
	ADDITIONAL_DETAILS = "/experience",
	SUGGESTION_MAPPING = "/get_suggestion_messages",
	ANALYZE_QUESTION = "/analyze_question_category",
}

export default API_DETAILS;
