const reportMapping = {
	primary_questions: {
		Q1: "Why have you chosen chosen this particular school? How does this school selection meet your academic needs?",
		Q2: "What are your future goals post education?",
		Q3: "Why have you chosen this particular program?",
		Q4: "What personal experience has influenced you to choose the specific career path?",
		Q5: "What is your past work experience or the related work experience?",
		Q6: "What were some of your academic experiences at previous educational institutes",
		Q7: "Achievements/Awards/Publications/Certifications",
	},

	paired_questions: {
		S1: "Why have you chosen this particular program and school? How will this combined choice help you meet your academic needs?",
		S2: "How are your previous academic and your previous work experience shaping your current decisions?",
		S3: "How your personal story is tying up with your academic experience?",
		S4: "How will your chosen program help achieve your long term goals?",
		S5: "How will your chosen school help achieve your long term goals?",
		S6: "How the exposure to an industry of interest that has influenced you to converge to a specific career path?",
		S7: "How your past work experience has influenced your long term goals post-graduation?",
		S8: "How your academic work can be confirmed by your choice of school?",
		S9: "How your personal experience has shaped your future goals?",
		S10: "What personal experience has led your eyes on the current career path and how the choice of program will assist it?",
		S11: "Which academic work triggered the choice of program for you?",
		S12: "What personal experience has led your eyes on the current career path and how the choice of program will assist it?",
		S13: "How your past work experience will help you in becoming a better fit at the chosen school?",
		S14: "Which skill you have learned during your time in the industry could be enhanced by the program of your choice?",
		S15: "How the your academic research work or learnings are connecting the dots with your future goals?",
	},
};

const contentColorMapping = {
	red: "#e74c3c",
	amber: "#f7b731",
	green: "#2ecc71",
};

const contentTitleMapping = {
	Q1: "Choice of the school",
	Q2: "Future goals post education",
	Q3: "Choice of program",
	Q4: "Self-motivation",
	Q5: "Work experience",
	Q6: "Academic experience",
};

const languageStyling = {
	regular: {
		borderBottom: "border-bottom: 1px solid rgba(0,0,0,0.5)",
		class: "regular",
	},

	s: {
		borderBottom: "border-bottom: 2px solid purple",
		padding: "0em 0.15em",
		class: "s",
	},
	v: {
		borderBottom: "border-bottom: 2px solid var(--color-brand)",
		class: "v",
	},
	tw: {
		borderBottom: "border-bottom: 2px solid #cc0000",
		class: "tw",
	},
	g: {
		borderBottom: "border-bottom: 2px solid #22F3B2",
		class: "g",
	},
	lp: {
		borderBottom: "background-color: rgba(244, 217, 66, 0.2)",
		class: "lp",
	},
	ls: {
		borderBottom: "background-color: rgba(244, 139, 58, 0.3)",
		class: "ls",
	},
	dimmed: {
		borderBottom: "border-bottom: 1px solid rgba(0,0,0,0.05)",
		class: "regular",
	},
};

const errorMessage = "Please contact support@ischoolconnect.com for further assistance.";

export { contentColorMapping, contentTitleMapping, reportMapping, languageStyling, errorMessage };
