import { NgcCookieConsentConfig } from "ngx-cookieconsent"
import { fetchDomain } from "../lib/utils.functions"

export const cookieConfig: NgcCookieConsentConfig = {
	"cookie": {
		"domain": fetchDomain(),
		"name": "cookieconsent_status_via",
		"secure": true
	},
	"position": "bottom",
	"theme": "classic",
	palette: {
		popup: {
		  background: "#000000",
		  text: "#ffffff",
		},
		button: {
		  background: "var(--color-brand)",
		  text: "#fff",
		},
	  },
	"type": "info",
	"content": {
		"message": "This website uses cookies to ensure you get the best experience on our website.",
		"dismiss": "Got it!",
		"deny": "Refuse cookies",
		"link": "Learn more",
		"href": "https://cookiesandyou.com",
		"policy": "Cookie Policy"
	}

}
