import Quill from "quill";

var Underline = Quill.import("formats/underline");

// FIXME: This needs to be replaced with a regular inline blot for consistency purposes
class GrammarHighlight extends Underline {
	static create({ id, background } = { id: "grammar_underline", background: "" }) {
		let node = super.create();
		node.setAttribute("id", id);
		node.setAttribute("style", `background-color: ${background}`);

		return node;
	}
}
GrammarHighlight.blotName = "grammar-underline";
GrammarHighlight.tagName = "mark";

Quill.register(GrammarHighlight, true);
