import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "sop-unsupported-message",
  templateUrl: "./unsupported-message.component.html",
  styleUrls: ["./unsupported-message.component.scss"]
})
export class UnsupportedMessageComponent {
  constructor(private _cookie: CookieService) { }
  goBack() {
    window.location.href = this._cookie.get("sop_dashboard_url");
  }
}
