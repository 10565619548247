import { Component, Input } from "@angular/core";
@Component({
	selector: "sop-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
	@Input() isIschoolBrandingAllowed: boolean;
	constructor() {}
}
